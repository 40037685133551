import React, { useCallback, useEffect, useRef } from 'react';
import { isIOS } from 'app/utils/browserHelpers';
import GlobalMessagesApp from './globalMessagesApp';
import { useServiceBootstrapper } from './globalMessagesHooks';
import { SHOW_MESSAGE_EVENT, HIDE_MESSAGE_EVENT } from './globalMessagesConstants';
import { getGlobalMessagesRoot, getMessagePriority, onServiceStart, onServiceStop } from './globalMessagesUtils';

function GlobalMessagesService(props) {
  const { currentMessage, showMessage, hideMessage } = useServiceBootstrapper(props);
  const ref = useRef(getGlobalMessagesRoot());

  // lower value means higher priority
  const shouldReplaceCurrentMessage = useCallback(
    newMessage => {
      return getMessagePriority(newMessage) <= getMessagePriority(currentMessage);
    },
    [currentMessage]
  );

  const onShowMessage = useCallback(
    event => {
      const newMessage = event.detail;
      if (!currentMessage || shouldReplaceCurrentMessage(newMessage)) {
        showMessage(newMessage);
      }
    },
    [currentMessage, shouldReplaceCurrentMessage, showMessage]
  );

  useEffect(() => {
    const globalMessagesRoot = ref.current;
    globalMessagesRoot?.addEventListener(SHOW_MESSAGE_EVENT, onShowMessage);
    globalMessagesRoot?.addEventListener(HIDE_MESSAGE_EVENT, hideMessage);

    onServiceStart();
    window.addEventListener(isIOS() ? 'pagehide' : 'beforeunload', onServiceStop);

    return () => {
      globalMessagesRoot?.removeEventListener(SHOW_MESSAGE_EVENT, onShowMessage);
      globalMessagesRoot?.removeEventListener(HIDE_MESSAGE_EVENT, hideMessage);
      window.removeEventListener(isIOS() ? 'pagehide' : 'beforeunload', onServiceStop);
    };
  }, [hideMessage, onShowMessage]);

  return <GlobalMessagesApp {...currentMessage} />;
}

export default GlobalMessagesService;
