import { useEffect, useState } from 'react';
import { getFlashStorageRecord, getPageSpecificOptions } from './globalMessagesUtils';

const { pageType } = window.inlineGlobalConfig;

export function useServiceBootstrapper() {
  const [messageDescriptor, setMessageDescriptor] = useState(null);

  function showMessage(originalDescriptor) {
    setMessageDescriptor(populatePresets(originalDescriptor));
  }

  function hideMessage() {
    setMessageDescriptor(null);
  }

  function populatePresets(message) {
    return message
      ? {
          ...getPageSpecificOptions(message.pageType || pageType),
          ...message,
          onClose: () => {
            hideMessage();
            if (message.onClose) {
              message.onClose();
            }
          },
        }
      : null;
  }

  useEffect(() => {
    const flashMessage = getFlashStorageRecord();
    if (flashMessage) {
      showMessage(flashMessage);
    }
  });

  return {
    currentMessage: messageDescriptor,
    showMessage,
    hideMessage,
  };
}
