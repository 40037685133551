import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { getElementHeightNoPadding } from 'app/utils/helpers';
import { MAX_DISPLAY_TIME } from './globalMessagesConstants';
import './alerts.scss';

const GlobalMessagesApp = ({
  autoHide,
  customClass,
  headerSelector,
  sticky,
  message,
  messageId,
  type,
  onClose,
  stickyClass,
  values,
  showCloseButton,
  customColor,
  displayTime,
  customCloseButton,
}) => {
  const intl = useIntl();
  const [isFadeOutInProcess, setIsFadeOutInProcess] = useState(false);
  const [isSticking, setIsSticking] = useState(false);

  const globalMessageDIV = useRef(null);

  const isOpen = !!message || !!messageId;

  const headerHeight = useMemo(() => {
    let height = 0;
    if (headerSelector) {
      [...document.querySelectorAll(headerSelector)].forEach(headerElement => {
        height += getElementHeightNoPadding(headerElement);
      });
    }
    return height;
  }, [headerSelector]);

  function handleTransition() {
    if (isFadeOutInProcess) {
      setIsFadeOutInProcess(false);
      onClose();
    }
  }

  function handleScrollEvent() {
    const shouldBeSticking = window.pageYOffset > headerHeight;
    if (isSticking !== shouldBeSticking) {
      setIsSticking(shouldBeSticking);
    }
  }

  function closeAlertBox() {
    setIsFadeOutInProcess(true);
  }

  useEffect(() => {
    let timer = null;
    if (autoHide) {
      timer = window.setTimeout(closeAlertBox, displayTime * 1000);
    }

    return () => window.clearTimeout(timer);
  }, [autoHide, displayTime]);

  useEffect(() => {
    const globalMessageDivEl = globalMessageDIV.current;
    if (sticky && isOpen) {
      document.addEventListener('scroll', handleScrollEvent);
    }

    globalMessageDivEl.addEventListener('transitionend', handleTransition);

    handleScrollEvent();

    return () => {
      document.removeEventListener('scroll', handleScrollEvent);
      globalMessageDivEl.removeEventListener('transitionend', handleTransition);
    };
  });

  if (!isOpen) return <div ref={globalMessageDIV} />;

  const dynamicClasses = [];

  if (sticky) {
    dynamicClasses.push('is-sticky');
    if (isSticking) {
      dynamicClasses.push(stickyClass);
    }
  }

  if (isFadeOutInProcess) {
    dynamicClasses.push('is-closing');
  } else if (isOpen) {
    dynamicClasses.push('is-open');
  } else {
    dynamicClasses.push('is-closed');
  }

  let messageElement = null;
  if (messageId) {
    messageElement = <FormattedMessage values={values} id={messageId} description="Alert by id" />;
  } else {
    messageElement = <div dangerouslySetInnerHTML={{ __html: message }} />;
  }

  return (
    <div
      className={`alert-box ${type} ${customClass} ${dynamicClasses.join(' ')}`}
      ref={globalMessageDIV}
      role="alert"
      aria-atomic="true"
      style={{ backgroundColor: customColor }}
    >
      {messageElement}
      {customCloseButton ||
        (showCloseButton && (
          <button
            aria-label={intl.formatMessage({ id: 'header.global.message.close' })}
            className="alert-box__close icon-close"
            type="button"
            onClick={() => setIsFadeOutInProcess(true)}
          />
        ))}
    </div>
  );
};

GlobalMessagesApp.defaultProps = {
  autoHide: false,
  customClass: '',
  headerSelector: '',
  message: '',
  messageId: '',
  type: '',
  sticky: false,
  stickyClass: 'is-sticky--fixed',
  values: {},
  showCloseButton: true,
  onClose: () => true,
  customColor: '',
  displayTime: MAX_DISPLAY_TIME,
  customCloseButton: null,
};

GlobalMessagesApp.propTypes = {
  autoHide: PropTypes.bool,
  customClass: PropTypes.string,
  headerSelector: PropTypes.string,
  message: PropTypes.string,
  messageId: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
  sticky: PropTypes.bool,
  stickyClass: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  showCloseButton: PropTypes.bool,
  customColor: PropTypes.string,
  displayTime: PropTypes.number,
  customCloseButton: PropTypes.element,
};

export default GlobalMessagesApp;
